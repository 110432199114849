// variables

@white: #ffffff;
@transparent: transparent;
// blue shades
@blue1: #e9f3fa;
@blue2: #cae3f3;
@blue3: #a0cde9;
@blue4: #74b5de;
@blue5: #4b9fd4;
@blue6: #238acb;
@blue7: #1e75ad;
@blue8: #196290;
@blue9: #144f74;
@blue10: #103e5b;
@blue11: #092333;
// colors
@blue100: #f7fcff;
@blue200: #ceecff;
@blue300: #91c9ed;
@blue400: #4ca6e0;
@blue500: #1e75ad;
@blue600: #105f92;
@blue700: #054874;
@blue800: #023657;
@blue900: #001f33;
//
@grey2: #99a6ae;
@grey4: #ebf3f8;
@grey100: #f8f8f9;
@grey200: #e6e8e8;
@grey300: #a3adb3;
@grey400: #63727c;
@grey500: #354b59;
@grey600: #223744;
@grey700: #0f202b;
@grey800: #081721;
@grey900: #00090e;
//
@orange100: #fff9f0;
@orange200: #ffedd1;
@orange300: #ffcb7d;
@orange400: #ffae34;
@orange500: #e38900;
@orange600: #bd7200;
@orange700: #7e4c00;
@orange800: #3e2500;
@orange900: #211400;
//
@red100: #fff8f6;
@red200: #ffdcd4;
@red300: #f88167;
@red400: #f36748;
@red500: #d34323;
@red600: #b62b0d;
@red700: #8e1b01;
@red800: #5b1000;
@red900: #320900;
//
@green100: #f6fff8;
@green200: #ccf9d9;
@green300: #7ef29e;
@green400: #50f17e;
@green500: #23d354;
@green600: #08a534;
@green700: #037724;
@green800: #004514;
@green900: #01220a;

html,
body {
    padding: 0;
    margin: 0;
}
body {
    background-color: @blue100 !important;
    margin: 0;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.rc-virtual-list-holder > div {
    min-height: 48px;
}
// Necessary ant overrides that cannot be in the styled componenets
.rc-virtual-list-holder-inner {
    padding: 8px;
    .ant-select-item {
        border-radius: 8px !important;
        &:hover {
            background-color: @grey4;
        }
    }
}

.ant-select-dropdown:has(#appliedTo_list) {
    padding: 12px 8px;
}
#appliedTo_list + .rc-virtual-list {
    .rc-virtual-list-holder-inner {
        padding: 0px;
    }
}

.ant-form-item-children-icon {
    text-align: right !important;
    padding-right: 2px;
}

// Prevent browser autofill styles
input.ant-input,
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
    background: linear-gradient(0, white, white);
}

#login {
    .ant-form-item-has-feedback {
        input.ant-input:not(#password) {
            padding-right: 46px;
        }
        .ant-form-item-children-icon {
            right: 16px !important;
            width: 14px !important;
        }
        .ant-input-suffix {
            margin-right: 32px;
            padding-right: 0 !important;
        }
    }
}

// .ant-form-item-control-input {
//     .ant-form-item-children-icon {
//         svg[data-icon='check-circle'],
//         svg[data-icon='close-circle'] {
//             display: none;
//         }
//     }
//     .ant-form-item-children-icon:has(> .anticon-close-circle) {
//         display: none;
//     }
//     .anticon-close-circle {
//         display: none;
//         pointer-events: none;
//     }
// }
.ant-form-item-children-icon {
    display: none;
}
.ant-select-selection-item {
    cursor: pointer;
}
.ant-form-item-control-input-content {
    .ant-select-clear {
        display: none;
    }
}
#add_service {
    .ant-select.ant-select-allow-clear:hover {
        .ant-select-clear {
            right: 16px;
        }
    }
}

.ant-select.ant-select-allow-clear:hover {
    .ant-select-clear {
        width: 20px;
    }
}

#change-password,
#request-password {
    .ant-form-item-has-feedback {
        .ant-form-item-children-icon {
            right: 16px !important;
            width: 14px !important;
        }
        .ant-input-suffix {
            margin-right: 32px;
            padding-right: 0 !important;
        }
    }
}

.ant-select.ant-select-allow-clear:hover {
    .ant-select-clear {
        margin-top: -8px;
    }
}

// Date range min-width
.ant-picker {
    .ant-picker-range {
        min-width: 220px;
    }
}

.ant-picker-dropdown {
    line-height: 22px;

    .ant-picker-header-prev-btn {
        width: 20px;
        height: 20px;
        background-image: url('./Images/Icons/icon-left-circle.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 13px;
        margin-left: 8px;
        transition: background-image 300ms;
        &:hover {
            background-image: url('./Images/Icons/icon-left-circle-hover.svg');
        }
        .ant-picker-prev-icon {
            display: none;
        }
    }

    .ant-picker-header-super-prev-btn {
        width: 16px;
        height: 16px;
        background-image: url('./Images/Icons/icon-double-left-outlined.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 15px;
        transition: background-image 300ms;
        &:hover {
            background-image: url('./Images/Icons/icon-double-left-outlined-hover.svg');
        }
        .ant-picker-super-prev-icon {
            display: none;
        }
    }

    .ant-picker-header-next-btn {
        width: 20px;
        height: 20px;
        background-image: url('./Images/Icons/icon-right-circle.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 13px;
        margin-right: 8px;
        transition: background-image 300ms;
        &:hover {
            background-image: url('./Images/Icons/icon-right-circle-hover.svg');
        }
        .ant-picker-next-icon {
            display: none;
        }
    }

    .ant-picker-header-super-next-btn {
        width: 16px;
        height: 16px;
        background-image: url('./Images/Icons/icon-double-right-outlined.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 15px;
        transition: background-image 300ms;
        &:hover {
            background-image: url('./Images/Icons/icon-double-right-outlined-hover.svg');
        }
        .ant-picker-super-next-icon {
            display: none;
        }
    }

    .ant-picker-header {
        border-bottom-color: @blue100;
    }
    .ant-picker-header-view {
        font-weight: 400;
    }
    .ant-picker-content {
        .ant-picker-cell {
            &:before {
                height: 36px;
            }
        }
        th {
            color: @blue11;
            font-size: 12px;
            line-height: 20px;
        }
        .ant-picker-cell-in-view {
            color: @blue11;
        }
        .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
            background: @grey4;
        }
        .ant-picker-cell-inner {
            height: 36px;
            line-height: 36px;
            min-width: 36px;

            &:before {
                border-radius: 12px !important;
            }
        }
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
            .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
            .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background-color: @grey4;
    }
}

/////////////////////////////////////////////////////

.ant-form-item .ant-form-item {
    margin-bottom: 0;
}

.ant-form-item-explain {
    text-align: left;
}

.ant-form-item-has-success.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    color: #27ae60 !important;
}

.ant-select-arrow {
    color: #000000 !important;
}
.ant-btn-primary {
    box-shadow: none !important;
    text-shadow: none !important;
}
.ant-input-search-button {
    border-left: none !important;
    svg {
        fill: @primary-color;
    }
    &:active {
        border-color: #d9d9d9 !important;
    }
}

.ant-input-search {
    & .ant-input {
        border-right: none !important;
    }
    & .ant-input-search-button,
    & .ant-input-affix-wrapper {
        transition: all 0.3s;
    }

    &:hover {
        & .ant-input-search-button,
        & .ant-input-affix-wrapper {
            border-color: #2d92c4;
        }
    }
}

.ant-pagination.mini .ant-pagination-item {
    min-width: 40px;
    height: 40px;
    margin: 0;
    line-height: 40px;
    border-radius: 24px;
}

.ant-pagination.mini .ant-pagination-options,
.ant-pagination.mini .ant-pagination-jump-next {
    line-height: 40px;
}

.ant-pagination-item-active a {
    color: #ffffff;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
    display: none;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: @primary-color;
    border-color: @primary-color;
}

.ant-page-header-heading {
    justify-content: initial;

    .ant-page-header-heading-title {
        color: #262626;
        font-weight: 700;
        font-size: 32px;
        margin-right: 24px;
        margin-bottom: 0;
        padding-bottom: 4px;
    }
    @media all and (max-width: 1000px) {
        flex-direction: column;
    }
}
.infoWrap {
    @media all and (max-width: 1000px) {
        align-items: flex-start !important;
        flex-direction: column !important;
    }
}

.iconWrap {
    &:first-of-type {
        @media all and (max-width: 1000px) {
            margin-left: 0;
        }
    }
}
.actionsWrap {
    @media all and (max-width: 1000px) {
        margin-top: 16px;
    }
}

.ant-tooltip {
    .ant-tooltip-inner {
        background-color: #ffffff;
        color: #747474;
        border: 1px solid #e3f1f6;
    }

    .ant-tooltip-arrow-content {
        background-color: #ffffff;
        border: 1px solid #e3f1f6;
    }
}

.expand-button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */

    color: @primary-color;
}
// .expand-button.expand-button-hidden {
//     display: none !important;
// }

p:last-of-type {
    margin-bottom: 0;
}

// Archived services product list styles

.archivedDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.archivedServiceComponentWrap {
    button {
        opacity: 0.5;
        pointer-events: none;
    }
    .ant-page-header-heading-extra {
        display: flex;
    }
}

.archivedComponent {
    display: none;
}

.archivedServiceComponentWrap .archivedComponent {
    display: inline-flex;
    margin-left: 22px;
    font-size: 18px;
    line-height: 26px;
    justify-content: center;
    align-items: center;
    .ant-typography {
        margin-left: 4px;
        margin-right: 10px;
    }
}

// Paper sizes input overrides
.paperTypeSelectWrap {
    margin-bottom: 0;
    .ant-form-item-explain-error,
    .ant-form-item-children-icon {
        display: none;
    }
    .ant-select .ant-select-arrow {
        right: 16px !important;
    }
}

.ant-select-selection-item .size-specs {
    display: none;
}

.map-container {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;
}

// Preset list styles
.products-list-item {
    position: relative;
    border: 2px solid transparent;
    button {
        display: none;
        position: absolute;
        right: 16px;
        top: 13px;
    }
    &:hover {
        border: 2px solid #0c7ab5;
        button {
            display: inline-block;
        }
    }
}

// Tables
.services-table {
    .ant-table-thead {
        tr {
            th:hover {
                background-color: @grey4;
            }
        }
    }
}

.services-table {
    &.breakdown-table {
        .ant-table-row:has(.expandedRow) {
            & + .ant-table-expanded-row {
                .ant-table-cell {
                    // border-top-width: 0;
                }
            }
        }
        .ant-table-expanded-row {
            .ant-table-cell {
                background-color: @blue100 !important;
                // border-top: 1px solid @blue200;
                border-width: 0;
            }
        }
    }
}

.ant-select-multiple {
    .ant-select-selection-item {
        align-items: center;
    }
}

.ant-modal-content {
    .ant-select {
        width: 100% !important;
    }
}

.ant-modal-body {
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
        .ant-select-selection-search {
            height: 22px;
            margin: 0;
            .ant-select-selection-search-input {
                height: 22px !important;
            }
        }
    }
    .ant-select-selection-overflow-item {
        height: 22px;
    }
}

#reset-password {
    .ant-form-item-children-icon {
        margin-right: 10px;
    }
}

.restStageLists {
    .ant-card-body {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }
    .ant-tabs-nav {
        margin: 0;
    }
    .ant-tabs-nav::before {
        display: none;
        content: '';
    }
    .ant-tabs-tab {
        margin: 0;
        padding: 0;
    }
    .ant-tabs-tab {
        margin-left: 24px;
    }
    .ant-tabs-nav-list {
        .ant-tabs-tab:first-of-type {
            .tabWrap {
                margin-left: 0;
            }
        }
    }
    .ant-tabs-ink-bar {
        display: none;
    }
}

#edit_packaging {
    .ant-select-clear {
        display: none;
    }
}

.ant-popover-content {
    .ant-popover-arrow {
        display: none;
    }
}
.ant-popover-placement-bottom {
    padding-top: 0;
}
.ant-btn.actionsPopoverButton {
    border: none;
    background-color: transparent !important;
    padding: 0 10px;
}

.ant-btn.actionsPopoverButton.ant-popover-open {
    svg path {
        fill: @blue700;
    }
}

.ant-table-tbody {
    .ant-table-expanded-row {
        border: 0;
        .specRow {
            display: flex;
            margin-top: 24px;
            flex-flow: row wrap;
            .actionsStyles {
                flex: 1;
                display: flex;
                justify-content: flex-end;
            }
            .listItemContainer {
                display: flex;
                align-items: center;
                margin-right: 16px;
                border-right: 0;

                &.emissions {
                    border-right: 0 !important;
                    .listItem {
                        .emissionsValue {
                            color: @blue500 !important;
                            font-size: 16px;
                        }
                    }
                }
                &.actionsStyles {
                    border-left: 0;
                }
                &.appliedTo {
                    width: 80%;
                }
                &.storage {
                    width: 16%;
                }
                &.time {
                    width: 16%;
                }
                &.complexity {
                    width: 16%;
                }
                &.size {
                    width: 18%;
                }
                &.stockType {
                    width: 25%;
                }
                &.weight {
                    width: 16%;
                }
                &.gsm {
                    width: 12%;
                }
                &.printerPages {
                    width: 16%;
                }
                &.provider {
                    width: 60%;
                }
            }
            .listItemContainer {
                .listItem {
                    display: inline-flex;
                    .ant-typography {
                        color: @grey800;
                    }
                }
                .listItemLabel {
                    font-weight: normal;
                    color: @grey400 !important;
                }
            }

            .listItem {
                padding-left: 16px;
                margin-bottom: 0;
                border-left: 1px solid #d9e9f5;
                .ant-tag.ant-tag-default {
                    margin-bottom: 2px;
                }
            }
            .listItemLast {
                border-right: 1px solid #d9e9f5;
                display: inline-flex;
                max-width: 103px;
                .listItem {
                    padding-left: 0;
                    border-left: 0;
                }
            }

            & + .ant-row {
                border-top: 0 !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
            & + .specRow {
                .listItemContainer {
                    border-top-width: 0;
                }
                .listItemContainer:first-of-type,
                .listItemContainer:last-of-type {
                    border-radius: 0;
                }
            }
            .listItemContainer {
                &.emissions {
                    flex-grow: 1;
                    justify-content: flex-end;
                }
            }
        }
        // & + .ant-table-row {
        //     border-top-width: 0;
        // }
        .ant-table-cell {
            background-color: transparent !important;
            .specRow div {
                background-color: @blue100;
            }
            .specRow {
                & + .ant-row {
                    background-color: @blue100;
                }
            }
        }
    }
    .ant-table-row {
        &:hover {
            & + .ant-table-expanded-row + .ant-table-row {
                border-top-width: 0;
            }
        }
    }
    .ant-table-expanded-row {
        & + .ant-table-row {
            td {
                border-top-width: 0;
            }
        }
    }
    .ant-table-expanded-row[style*='display: none;'] {
        & + .ant-table-row {
            td {
                border-top-width: 1px;
            }
        }
    }
}
.ant-page-header-heading-extra {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.headerWrapButton {
    display: inline-flex;
    margin-left: 16px;
    button.ant-btn-icon-only {
        height: 32px;
        padding: 0;
        width: 32px;
        svg {
            height: auto !important;
            width: auto !important;
        }
    }
}
#app {
    display: flex;
    flex-flow: column;
    min-height: 100%;
}

.specRow:only-child,
.specRow:last-child {
    :first-child {
        border-bottom-left-radius: 16px !important;
    }
    :last-child {
        border-bottom-right-radius: 16px !important;
    }
}

label.ant-radio-wrapper {
    padding-bottom: 12px !important;
}

.ant-form-item-has-error {
    .textArea {
        textarea {
            &:focus {
                border-color: red !important;
                box-shadow: none !important;
            }
        }
    }
}
.appliedToPopover {
    .ant-popover-inner {
        border-radius: 4px;
        border: 1px solid @blue200;
        .ant-popover-inner-content {
            padding: 0;
            padding-bottom: 8px;
        }
    }
}

.appliedToPopoverWrap {
    display: block;
    margin-left: 8px;
    button {
        display: block;
    }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    float: right;
    transform: rotate(0deg);
    transition: transform 0.3s linear;
    transform-origin: center center;
    margin: 0;
}
.ant-collapse
    > .ant-collapse-item.ant-select-open
    > .ant-collapse-header
    .ant-collapse-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
    transform-origin: center center;
}

.createProductForm {
    .ant-form-item-children-icon,
    .ant-select-clear {
        display: none;
    }

    .ant-select-selector {
        .ant-select-selection-placeholder {
            color: #bfbfbf !important;
        }
    }
    .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        float: right;
        transform: rotate(0deg);
        transition: transform 0.3s linear;
        transform-origin: center center;
        margin: 0;
    }
    .ant-collapse
        > .ant-collapse-item.ant-collapse-item-active
        > .ant-collapse-header
        .ant-collapse-arrow {
        transform: rotate(180deg);
        transition: transform 0.3s linear;
        transform-origin: center center;
    }
    .ant-collapse.ant-collapse-icon-position-left {
        border-color: #e6e8e8;
        background-color: #f5f7f8;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: 0;
    }

    .ant-collapse-content.ant-collapse-content-active {
        border-color: #e6e8e8;
        .ant-collapse-content-box {
            .ant-row.ant-form-item {
                margin-bottom: 0;
            }
        }
    }
    .ant-collapse > .ant-collapse-item {
        border-color: #e6e8e8;
    }
    .ant-collapse-content-box {
        background-color: #f5f7f8;
    }
    .applyToSelect.ant-select-multiple {
        .ant-select-selector {
            padding-bottom: 0;
        }
    }
}

.endPointWrap {
    .modifiedDelivery {
        #deliveryAddress {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-right-width: 1px;
            input {
                border-top-right-radius: 8px;
                border-right-width: 1px;
                padding-right: 100px;
            }
        }
    }
    #deliveryAddress {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
        input {
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0;
        }
    }
    input::placeholder {
        color: #bfbfbf !important;
    }
    input:focus {
        border-bottom-right-radius: 0;
    }
    .ant-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .dynamicPlaceholder {
        color: #bfbfbf;
        font-size: 16px;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
}

.ant-select-show-search.ant-select.applyToSelect:not(
        .ant-select-customize-input
    ) {
    height: auto !important;

    .ant-select-selector {
        height: auto !important;
        padding: 9px 12px !important;
        padding-bottom: 5px !important;
        min-height: 48px;
    }

    .ant-select-selection-item {
        background-color: @grey4;
        border-width: 0;
        border-radius: 11px;
        display: inline-flex;
        font-size: 14px;
        height: 100%;
        line-height: 22px;
        margin: 0 8px 0 0;
        padding-left: 12px;
        padding-right: 12px;
    }
}

.ant-row.ant-form-item.ant-form-item-has-success {
    .ant-select-selection-overflow-item {
        margin-bottom: 4px;
    }
}

#add_package {
    .ant-select-clear {
        display: none;
    }
}
.noClearIcon {
    .ant-select-clear {
        display: none;
    }
}
.productButton {
    button {
        margin-bottom: 16px !important;
    }
}
.emissionsCell {
    color: @blue500;
    font-weight: 500;
}
.productsTable {
    .ant-table-cell:last-child {
        width: 44px !important;
    }
    .actionsPopoverButton {
        margin-left: 14px;
    }
    // .ant-table-cell.ant-table-row-expand-icon-cell,
    // .ant-table-expand-icon-col {
    //     width: 40px !important;
    //     min-width: 40px !important;
    // }
}

.ant-input-number.ant-input-number-disabled {
    background-color: transparent !important;
}

.reportsContent {
    display: flex;
    min-height: 100%;
    .picker-container {
        width: 516px;
        .ant-select {
            margin-right: 16px;
        }
        .ant-picker-range-separator + .ant-picker-input {
            input {
                text-align: right;
            }
        }
    }
    .ant-space-item:nth-of-type(2) {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .reportContentTabs {
            flex-grow: 1;
            flex-direction: column;
            .ant-tabs-content-holder {
                display: flex;
            }
        }
    }
    .centerLoader {
        min-height: 100% !important;
    }
}

#reportsPage {
    height: 100%;
    .reportContentTabs {
        min-height: 100%;
        .ant-tabs-tab-btn {
            color: @grey400;
            font-size: 18px;
            font-weight: 400;
        }
        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                font-weight: 600;
            }
        }
        .ant-tabs-nav::before {
            display: none;
            content: '';
        }
    }
}
.productTab {
    .ant-tabs-nav-list {
        .ant-tabs-tab {
            margin: 0px;
            .ant-tabs-tab-btn {
                font-weight: 400 !important;
            }
        }
        > .ant-tabs-tab {
            margin-right: 16px;
        }
    }
}
